// App.js
import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import './App.css';
import { useDropzone } from 'react-dropzone'
import { useApi } from './api'
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Input from './components/common/Input/Input'
import UploadGrey from './assets/icons/UploadGrey.svg'
import File from './assets/icons/File.svg'
import Trash from './assets/icons/Trash.svg'
import Snackbar from '@mui/material/Snackbar'
import emailPNG from './assets/icons/email.png'
import Alert from '@mui/material/Alert'
import { emailRegex, baseStyle, focusedStyle, acceptStyle, rejectStyle, safeS3FileName } from './components/common/ConstsAndFunctions';
import { ScoopLoader } from './components/common/Spinner/ScoopLoader'
import runConfetti from './Confetti';  // Importing the confetti function
import Cookies from 'js-cookie';

const getHubspotUTK = () => {
  const cookie = Cookies.get('hubspotutk')
  return cookie ? cookie : ''
}

const theme = createTheme({
  typography: {
    h8: {
      color: '#14092a',
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
});

function App() {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      // 'application/vnd.ms-excel': [],
      'text/csv': [],
      'text/xlsx': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/zip': [],
      'application/pdf': [],
      'text/html': [],
    },
  })

  const [signUpUser, setSignUpUser] = useState({
    fullName: '',
    username: '',
    email: ''
  })
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    password: '',
  })
  const [touched, setTouched] = useState({
    fullName: false,
    email: false,
    dataDescription: false,
    dataQuestion: false
  });

  const [isLoading, setIsLoading] = useState(false)
  const [hideDropZone, setHideDropZone] = useState(false)
  const [key, setKey] = useState('')
  const [skipAccountCreation, setSkipAccountCreation] = useState(true);
  const [dataDescription, setDataDescription] = useState('');
  const [dataQuestion, setDataQuestion] = useState('');
  const [isDataValidated, setIsDataValidated] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [isDoneDone, setIsDoneDone] = useState(false);

  const confettiRef = useRef(null);  // Ref for the div to run confetti in
  const canvasRef = useRef(null);

  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    // Parse the URL query parameters
    const params = new URLSearchParams(window.location.search);
    if (params.get('isDev') === 'true') {
      setIsDev(true);
    }
  }, []); // Only run this effect once on mount

  const handleSignUpInputChange = (fieldName, value) => {
    setSignUpUser((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }))
  }


  const isValidText = (text) => {
    const trimmedText = text.trim();
    const charCount = trimmedText.length;
    const spaceCount = (trimmedText.match(/\s/g) || []).length;
    return charCount > 20 && spaceCount >= 3;
  };


  const validateAll = () => {
    let isTextFieldsValid = true;
    let newErrors = { ...errors };

    // ✅ Always validate full name, not just if it was touched
    if (signUpUser.fullName.trim().length < 3) {
      newErrors.fullName = 'Enter your name';
      isTextFieldsValid = false;
    } else {
      newErrors.fullName = '';
    }

    // ✅ Always validate email, not just if it was touched
    if (!signUpUser.email || !emailRegex.test(signUpUser.email)) {
      newErrors.email = 'Enter a valid email';
      isTextFieldsValid = false;
    } else {
      newErrors.email = '';
    }

    // ✅ Keep text area validation dependent on `touched`
    if (touched.dataDescription && !isValidText(dataDescription)) {
      newErrors.dataDescription = 'Describe your data as thoroughly as possible';
      isTextFieldsValid = false;
    } else {
      newErrors.dataDescription = '';
    }

    if (touched.dataQuestion && !isValidText(dataQuestion)) {
      newErrors.dataQuestion = 'Provide a detailed question relevant to your data';
      isTextFieldsValid = false;
    } else {
      newErrors.dataQuestion = '';
    }

    setErrors(newErrors);
    setIsFormValidated(isTextFieldsValid && isDataValidated);
  };




  const handleInputChange = (fieldName, value) => {
    setSignUpUser((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    validateAll(); // Ensure validation runs after every input change
  };


  const handleTextFieldChange = (fieldName, event) => {
    const { value } = event.target;
    if (fieldName === 'dataDescription') {
      setDataDescription(value);
    } else if (fieldName === 'dataQuestion') {
      setDataQuestion(value);
    }
  };

  const handleTextFieldBlur = (fieldName) => {
    setTouched(prev => ({ ...prev, [fieldName]: true }));
    validateAll(); // Trigger validation on blur to show errors after interaction
  };


  const handleChange = (event, fieldName) => {
    const { value } = event.target;
    if (fieldName === 'fullName' || fieldName === 'email') {
      handleSignUpInputChange(fieldName, value);
    } else if (fieldName === 'dataDescription' || fieldName === 'dataQuestion') {
      if (fieldName === 'dataDescription') {
        setDataDescription(value);
      } else {
        setDataQuestion(value);
      }
    }
    if (!touched[fieldName]) {
      setTouched(prev => ({ ...prev, [fieldName]: true }));
    }
  };

  const handleBlur = (fieldName) => {
    setTouched(prev => ({ ...prev, [fieldName]: true }));
    validateAll();
  };


  const isValidSentence = (text) => {
    return text.trim().split(/[.!?]+/).some(sentence => sentence.trim().length > 10);
  };

  const validateDescriptionAndQuestion = () => {
    let descriptionValid = isValidSentence(dataDescription);
    let questionValid = isValidSentence(dataQuestion);

    return descriptionValid && questionValid;
  };


  const validateSignUp = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (signUpUser.fullName.length < 3) {
      newErrors.fullName = 'Please enter your full name';
      isValid = false;
    } else {
      newErrors.fullName = '';
    }

    if (!signUpUser.email || !emailRegex.test(signUpUser.email)) {
      newErrors.email = 'Please enter a valid business email';
      isValid = false;
    } else {
      newErrors.email = '';
    }

    setErrors(newErrors);
    return isValid;
  };




  const acceptedFileItems = acceptedFiles.map((file) => (
    <Typography
      style={{
        color: 'blue',
        fontSize: '10px',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {file.path} - {file.size} bytes
    </Typography>
  ))

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )


  const { postData: postDataScoopWebAPI } = useApi(
    'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/scoopwebapi'
  )

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUpload()
    }
  }, [acceptedFiles])


  const [uploadComplete, setUploadComplete] = useState(false);

  const handleUpload = async () => {
    setIsLoading(true);
    let payload = { action: 'uploadFile', isDev: isDev };

    try {
      const presignedData = await postDataScoopWebAPI({
        ...payload,
        fileName: safeS3FileName(acceptedFiles[0].name),
        fileType: acceptedFiles[0].type,
      });

      setKey(presignedData.url.replace('https://scoop-web-uploads.s3.amazonaws.com/', ''));

      await fetch(presignedData.signedRequest, {
        method: 'PUT',
        body: acceptedFiles[0],
        headers: {
          'Content-Type': acceptedFiles[0].type,
        },
      });

      // setProgress(100);
      setIsLoading(false);
      setHideDropZone(true);
      setUploadComplete(true); // Indicate that upload is complete
    } catch (error) {
      setIsLoading(false);
      console.error('Error uploading the file:', error);
      // setProgress(0);
      setUploadComplete(false); // Reset the upload complete flag
    }
  };

  useEffect(() => {
    if (uploadComplete && key) {
      validateData();
      setUploadComplete(false); // Reset the upload complete flag after validation
    }
  }, [uploadComplete, key]); // Depend on uploadComplete and key

  // Function to reset the dropzone
  const resetDropzone = () => {
    setHideDropZone(false); // Ensure the dropzone is visible again
    setKey(Date.now()); // Update the key to force re-render and reset input
  };

  const validateData = async () => {
    if (!key) {
      console.error("Key is not set yet.");
      return;
    }

    setIsLoading(true);
    let payload = {
      action: 'validateDataFile',
      key: key,
      isDev: isDev,
    };

    try {
      const result = await postDataScoopWebAPI(payload);
      if (result?.result && JSON.parse(result.result).result === 'Success') {
        setIsLoading(false);
        setIsDataValidated(true);
        showSnackbar('Data validated successfully! Please fill out the rest of the form so you can see what Scoop can do with your data!', 'success');
      } else
        if (typeof result === 'undefined') { //timeout
          setIsLoading(false);
          setIsDataValidated(true);
          showSnackbar('Data validated successfully! Please fill out the rest of the form so you can see what Scoop can do with your data!', 'success');
        } else {
          setIsLoading(false);
          setIsDataValidated(false);
          showSnackbar('Error validating file, please try a different data file.', 'error');
          resetDropzone(); // Clear the invalid file
        }
    } catch (error) {
      setIsLoading(false);
      setIsDataValidated(true);
      showSnackbar('Got it. Please note that larger data loads may take longer for a presentation to generate', 'success');
      resetDropzone(); // Clear the invalid file
    }
  };




  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setOpenSnackbar(true)
  }


  const handleSubmit = () => {
    validateAll(); // Re-validate before allowing submission
    if (!isFormValidated || !isDataValidated) return; // Prevent incorrect submissions

    let payload = {
      action: "createAIPresentation",
      email: signUpUser.email,
      name: signUpUser.fullName,
      dataQuestion: dataQuestion,
      dataDescription: dataDescription,
      s3Key: key,
      isDev: isDev,
      hubspotUTK: getHubspotUTK(),
      source: 'ScoopWebsite'
    };

    postDataScoopWebAPI(payload);

    if (confettiRef.current) {
      setIsDoneDone(true);
      window.parent.location.href = "https://www.scoopanalytics.com/ai-data/thank-you";
      runConfetti(confettiRef.current);
    }

    showSnackbar('Give us some time to work our magic and we will email you when your Scoop is ready!', 'success');
  };


  return (
    <ThemeProvider theme={theme}>
      <div ref={confettiRef} className="App" style={{ width: '100%', height: '600px', margin: '0 auto', padding: '10px', boxSizing: 'border-box', position: 'relative' }}>
        {!isDoneDone && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
            width="100%"
          >
            {!hideDropZone && (
              <Typography variant="h8" gutterBottom>
                Drop an Excel or CSV file with your data here
              </Typography>
            )}
            <section className="container">
              {!hideDropZone && (
                <div key={key} {...getRootProps({ className: 'dropzone', style })}>
                  <input {...getInputProps()} />
                  <img src={UploadGrey} alt={'upload'} />
                  <div style={{ margin: '8px 0 16px 0' }}>CSV, XLSX, HTML or other</div>
                  <div style={{ color: '#2B1630' }}>
                    Drag 'n' drop your data file here or
                  </div>
                  <a style={{ color: '#BF0A46', cursor: 'pointer' }}>
                    click here to select file from your computer
                  </a>
                </div>
              )}
              {acceptedFiles.length > 0 && isDataValidated && (
                <>
                  <ul>{acceptedFileItems}</ul>
                </>
              )}
            </section>


            <Typography variant="h8" gutterBottom>
              How would you describe the data in the file?
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={3} // Fixed to 5 rows
              placeholder="Describe your data here..."
              variant="outlined"
              value={dataDescription}
              onChange={(e) => handleTextFieldChange('dataDescription', e)}
              onBlur={() => handleTextFieldBlur('dataDescription')}
              error={touched.dataDescription && !!errors.dataDescription}
              helperText={touched.dataDescription ? errors.dataDescription : ''}
            />

            <Typography variant="h8" gutterBottom>
              Ask a question about your data
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={3} // Fixed to 5 rows
              placeholder="Enter your question here..."
              variant="outlined"
              value={dataQuestion}
              onChange={(e) => handleTextFieldChange('dataQuestion', e)}
              onBlur={() => handleTextFieldBlur('dataQuestion')}
              error={touched.dataQuestion && !!errors.dataQuestion}
              helperText={touched.dataQuestion ? errors.dataQuestion : ''}
            />


            <Box
              className={'loginInput-no-margin'}
              sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
            >

              <Input
                type={'text'}
                value={signUpUser.fullName}
                onChange={e => handleChange(e, 'fullName')}
                onBlur={() => handleBlur('fullName')}
                error={touched.fullName && !!errors.fullName}
                helperText={touched.fullName ? errors.fullName : ''}
                label="Your name"
              />

              <Input
                type={'text'}
                value={signUpUser.email}
                onChange={e => handleChange(e, 'email')}
                onBlur={() => handleBlur('email')}
                error={touched.email && !!errors.email}
                helperText={touched.email ? errors.email : ''}
                label="Business email"
              />

            </Box>


            {/* Submit Button */}
            <Button variant="contained" color="primary" fullWidth disabled={!isFormValidated || !isDataValidated} onClick={handleSubmit}>
              Build my presentation!
            </Button>
          </Box>

        )}


        {confettiRef.current && (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            style={{
              position: 'absolute',  // Absolutely position within the relative container
              bottom: 150,             // Align to the bottom of the container
              // left: 0,               // Stretch from the left edge
              right: 0,              // to the right edge of the container
              width: '100%'          // Full width of the container
            }}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled" style={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>

        )}
        {isLoading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Modal-like background
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1300, // Ensure it appears above other content
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '300px', // Adjust as needed
                height: '300px', // Adjust as needed
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {/* Loader GIF */}
              <ScoopLoader size={235} />

              {/* Text Box */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  width: '60%',
                  backgroundColor: 'rgba(10, 15, 15, 0.9)', // Semi-transparent background
                  padding: '16px',
                  textAlign: 'center',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#FFF',
                  }}
                >
                  Creating magic...do not navigate away
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isDoneDone && (
          <EmailMessageDoneDone email={signUpUser.email} />
        )}

      </div>
    </ThemeProvider>
  );
}

const EmailMessageDoneDone = ({ email }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full viewport height to center vertically
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <img
        src={emailPNG}
        alt="Email Icon"
        style={{
          width: '200px', // Set horizontal size of the image
          height: 'auto', // Maintain aspect ratio
          marginBottom: '20px', // Space between the image and text
        }}
      />
      <Typography
        variant="h6"
        sx={{
          fontSize: '16px',
          color: '#333',
        }}
      >
        Keep an eye on your email. We will email you instructions to {email} in a few minutes.
      </Typography>
    </Box>
  );
};


export default App;
