
export const useApi = (url) => {

    const postData = async (action) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(action),
            })
            if (!response.ok) {
                if (response?.message === 'Unauthorized') {
                    console.log('*****Unauthorized')
                }
                // Not OK - throw an error
                throw new Error('Network response was not ok', response)
            }

            // Try parsing the response into JSON
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
    return { postData }
}










