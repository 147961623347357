// Confetti.js
import confetti from 'canvas-confetti';

const runConfetti = (container) => {
  if (!container) {
    console.error("Container element not provided or not yet available.");
    return;
  }

  // Ensure we create and use a native canvas element.
  let canvas = document.createElement('canvas');
  canvas.style.position = 'absolute';
  canvas.style.top = '0';
  canvas.style.left = '0';
  canvas.style.pointerEvents = 'none';  // Make sure clicks pass through the canvas
  container.style.position = 'relative'; // Ensure the container can hold the absolute canvas
  container.appendChild(canvas);

  // Adjust the canvas to fill the container
  canvas.width = container.offsetWidth;
  canvas.height = container.offsetHeight;

  // Create the confetti effect
  const myConfetti = confetti.create(canvas, { resize: true });
  myConfetti({
    spread: 160,
    particleCount: 150,
    origin: { y: 0.6 }
  });

  // Remove the canvas after the animation completes
  setTimeout(() => {
    container.removeChild(canvas);
  }, 5000);  // 3000 ms = 3 seconds
};

export default runConfetti;
