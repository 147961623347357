export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  //   padding: '16px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '90px',
  width: '100%',
  fontSize: '14px',
}

export const focusedStyle = {
  borderColor: '#FFA6C3',
  backgroundColor: '#FCE7EE',
}

export const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#e5fbd7',
}

export const rejectStyle = {
  borderColor: '#ff1744',
  backgroundColor: '#ffd7d7',
}


// when user uploads an image to S3, make sure the filename is safe
export function safeS3FileName(str) {
  const randomChars = Math.random().toString(36).substring(2, 6); // Generate 4 random alphanumeric characters
  const extensionIndex = str.lastIndexOf('.'); // Find the last dot for the extension
  if (extensionIndex !== -1) {
    // Split the file name into name and extension
    const name = str.substring(0, extensionIndex);
    const extension = str.substring(extensionIndex);
    str = `${name}_${randomChars}${extension}`;
  } else {
    // If there's no extension, just append the random characters
    str += `_${randomChars}`;
  }
  return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
}